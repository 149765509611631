// Generated by Framer (1c1e241)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const enabledGestures = {DCXZDI5VY: {hover: true, pressed: true}, mUYFfTqzq: {hover: true, pressed: true}, PaAGulxup: {hover: true, pressed: true}, YtyKZu0FI: {hover: true, pressed: true}};

const cycleOrder = ["YtyKZu0FI", "DCXZDI5VY", "PaAGulxup", "mUYFfTqzq"];

const serializationHash = "framer-UNJ6e"

const variantClassNames = {DCXZDI5VY: "framer-v-md8ggg", mUYFfTqzq: "framer-v-mr8krn", PaAGulxup: "framer-v-wmnp93", YtyKZu0FI: "framer-v-acnkso"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Instagram: "PaAGulxup", LinkedIn: "DCXZDI5VY", Twitter: "YtyKZu0FI", YouTube: "mUYFfTqzq"}

const getProps = ({background, height, icon, id, link, width, ...props}) => { return {...props, bD3fRXosl: link ?? props.bD3fRXosl, NJ_IHjdSV: icon ?? props.NJ_IHjdSV ?? "var(--token-2e606580-c5cb-45c2-a66e-07cb8dbe5a38, rgb(116, 33, 252)) /* {\"name\":\"Purple\"} */", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "YtyKZu0FI", wtA7o7rpL: background ?? props.wtA7o7rpL ?? "rgb(255, 255, 255)"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;icon?: string;background?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, NJ_IHjdSV, wtA7o7rpL, bD3fRXosl, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "YtyKZu0FI", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-UNJ6e", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={bD3fRXosl} openInNewTab><motion.a {...restProps} className={`${cx("framer-acnkso", className)} framer-1tz14ay`} data-framer-name={"Twitter"} layoutDependency={layoutDependency} layoutId={"YtyKZu0FI"} ref={ref ?? ref1} style={{backgroundColor: wtA7o7rpL, borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, ...style}} {...addPropertyOverrides({"DCXZDI5VY-hover": {"data-framer-name": undefined}, "DCXZDI5VY-pressed": {"data-framer-name": undefined}, "mUYFfTqzq-hover": {"data-framer-name": undefined}, "mUYFfTqzq-pressed": {"data-framer-name": undefined}, "PaAGulxup-hover": {"data-framer-name": undefined}, "PaAGulxup-pressed": {"data-framer-name": undefined}, "YtyKZu0FI-hover": {"data-framer-name": undefined}, "YtyKZu0FI-pressed": {"data-framer-name": undefined}, DCXZDI5VY: {"data-framer-name": "LinkedIn"}, mUYFfTqzq: {"data-framer-name": "YouTube"}, PaAGulxup: {"data-framer-name": "Instagram"}}, baseVariant, gestureVariant)}><motion.div className={"framer-nez0h0-container"} layoutDependency={layoutDependency} layoutId={"naSriuAgo-container"} style={{opacity: 1}} variants={{"DCXZDI5VY-hover": {opacity: 0.8}, "DCXZDI5VY-pressed": {opacity: 0.7}, "mUYFfTqzq-hover": {opacity: 0.8}, "mUYFfTqzq-pressed": {opacity: 0.7}, "PaAGulxup-hover": {opacity: 0.8}, "PaAGulxup-pressed": {opacity: 0.7}, "YtyKZu0FI-hover": {opacity: 0.8}, "YtyKZu0FI-pressed": {opacity: 0.7}}}><Phosphor color={NJ_IHjdSV} height={"100%"} iconSearch={"House"} iconSelection={"TwitterLogo"} id={"naSriuAgo"} layoutId={"naSriuAgo"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"} {...addPropertyOverrides({DCXZDI5VY: {iconSelection: "LinkedinLogo"}, mUYFfTqzq: {iconSelection: "YoutubeLogo"}, PaAGulxup: {iconSelection: "InstagramLogo"}}, baseVariant, gestureVariant)}/></motion.div></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-UNJ6e [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-UNJ6e .framer-1tz14ay { display: block; }", ".framer-UNJ6e .framer-acnkso { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 40px; justify-content: center; overflow: hidden; padding: 10px 10px 10px 10px; position: relative; text-decoration: none; width: 40px; will-change: var(--framer-will-change-override, transform); }", ".framer-UNJ6e .framer-nez0h0-container { flex: 1 0 0px; height: 100%; pointer-events: none; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-UNJ6e .framer-acnkso { gap: 0px; } .framer-UNJ6e .framer-acnkso > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-UNJ6e .framer-acnkso > :first-child { margin-left: 0px; } .framer-UNJ6e .framer-acnkso > :last-child { margin-right: 0px; } }", ".framer-UNJ6e.framer-v-md8ggg .framer-acnkso, .framer-UNJ6e.framer-v-wmnp93 .framer-acnkso, .framer-UNJ6e.framer-v-mr8krn .framer-acnkso, .framer-UNJ6e.framer-v-acnkso.hover .framer-acnkso, .framer-UNJ6e.framer-v-acnkso.pressed .framer-acnkso { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 40px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"DCXZDI5VY":{"layout":["fixed","fixed"]},"PaAGulxup":{"layout":["fixed","fixed"]},"mUYFfTqzq":{"layout":["fixed","fixed"]},"ciL2mLj6q":{"layout":["fixed","fixed"]},"e0Yr99gEN":{"layout":["fixed","fixed"]},"t5DunM_7T":{"layout":["fixed","fixed"]},"M5LK1KNZx":{"layout":["fixed","fixed"]},"Z7JVi0Z1M":{"layout":["fixed","fixed"]},"Hvl0BsuwJ":{"layout":["fixed","fixed"]},"b8fy51t7v":{"layout":["fixed","fixed"]},"ifKSnKQV1":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"NJ_IHjdSV":"icon","wtA7o7rpL":"background","bD3fRXosl":"link"}
 * @framerImmutableVariables true
 */
const FramerE6jsPNobc: React.ComponentType<Props> = withCSS(Component, css, "framer-UNJ6e") as typeof Component;
export default FramerE6jsPNobc;

FramerE6jsPNobc.displayName = "Social Icon";

FramerE6jsPNobc.defaultProps = {height: 40, width: 40};

addPropertyControls(FramerE6jsPNobc, {variant: {options: ["YtyKZu0FI", "DCXZDI5VY", "PaAGulxup", "mUYFfTqzq"], optionTitles: ["Twitter", "LinkedIn", "Instagram", "YouTube"], title: "Variant", type: ControlType.Enum}, NJ_IHjdSV: {defaultValue: "var(--token-2e606580-c5cb-45c2-a66e-07cb8dbe5a38, rgb(116, 33, 252)) /* {\"name\":\"Purple\"} */", title: "Icon", type: ControlType.Color}, wtA7o7rpL: {defaultValue: "rgb(255, 255, 255)", title: "Background", type: ControlType.Color}, bD3fRXosl: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerE6jsPNobc, [...PhosphorFonts])